import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';

import { charLimitFree, charLimitRegistered, BASE_URL } from '../../config';
import ComplexityCard from './ComplexityCard';
import { setCookie, getCookie, getCharactersLeftThisMonth, countCharacters, cleanOldCookies } from '../../utils/cookies';
import { useAuth } from '../../context/AuthContext';
import RadioButtonGroup from './RadioButtonGroup';
import { charsPerPage } from '../../config';


const LeichteSpracheTranslator = () => {
  const [state, setState] = useState(() => {
    const savedState = Cookies.get('myAppState');
    return savedState ? JSON.parse(savedState) : { summarize: "1", mediopoint: false, languageLevel: 'leichte-sprache', showMarkdown: true };
  });

  useEffect(() => {
    Cookies.set('myAppState', JSON.stringify(state), { expires: 7 });
  }, [state]);

  const [text, setText] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  
  const [translations, setTranslations] = useState('');
  const [translationsMarkdown, setTranslationsMarkdown] = useState('');
  const [translationsShown, setTranslationsShown] = useState('');
  
  const [complexity, setComplexity] = useState('');
  const [GERLevel, setGERLevel] = useState('');
  const [grade, setGrade] = useState('');

  const [simplifiedComplexity, setSimplifiedComplexity] = useState('');
  const [simplifiedGERLevel, setSimplifiedGERLevel] = useState('');
  const [simplifiedGrade, setSimplifiedGrade] = useState('');
  const textAreaRef = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const { authState, getAccessToken } = useAuth();
  const { isLoggedIn } = authState;  // const navigate = useNavigate();

  useEffect(() => {
    textAreaRef.current.focus();
  }, []);

    
  const handleToggle = () => {
    setState(prevState => {
      const newMediopoint = !prevState.mediopoint;
      const updatedText = toggleHyphenMediopoint(translationsShown, newMediopoint);
      setTranslationsShown(updatedText);
      console.log("Toggle mediopoint: ", newMediopoint);
      return { ...prevState, mediopoint: newMediopoint };
    });
  };

  // const handleMarkdownToggle = () => {
    
  //   if (state.showMarkdown) {
  //     setTranslationsShown(translations);
  //   } else {
  //     setTranslationsShown(translationsMarkdown);
  //   };
  //   setState(prevState => ({ ...prevState, showMarkdown: !prevState.showMarkdown }));
  // };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length <= charsPerPage) {
      setText(newText);
      setCharCount(newText.length);
    } else {
      setText(newText.slice(0, charsPerPage));
      setCharCount(charsPerPage);
      setShowLimitAlert(true);
      setTimeout(() => setShowLimitAlert(false), 10000); // Hide alert after 3 seconds
    }
  };
  
  const handleSummarize = (newState) => {
    setState(prevState => ({ ...prevState, summarize: newState }));
  }

  const handleLanguageChange = (newLevel) => {
    // setLanguage(event.target.value);
    setState(prevState => ({ ...prevState, languageLevel: newLevel }));
  };

  const getLanguageText = (language) => {
    if (language === "leichte-sprache") {
      return "Für Menschen mit Lern-Schwierigkeiten oder mit Legasthenie.";
    } else if (language === "einfache-sprache") {
      return "Für Menschen, die komplexe Texte schwer verstehen.";
    } else if (language === "einfache-sprache-2") {
      return "Für Menschen, die komplexe Texte schwer verstehen.";
    }
    return "";
  };

  const handleCopy = () => {
    const appendText = `\n\nDieser Text wurde mit dem Leichte Sprache Übersetzer vereinfacht: https://www.leichte-sprache-uebersetzer.de/`;
    if (state.showMarkdown) { 
      navigator.clipboard.writeText(stripHtmlTags(translationsMarkdown + appendText));
    } else {
      navigator.clipboard.writeText(translations + appendText);
    }
    const token = localStorage.getItem('access_token');
    const headers = { Authorization: `Bearer ${token}` };

    const copiedData = {
      source_text: text,
      translated_text: translations,
      translated_text_formatted: translationsMarkdown,
      language_level: state.languageLevel,
      summarize: state.summarize,
      formatted: state.showMarkdown,
    };

    // Send the POST request without waiting for a response
    axios.post(`${BASE_URL}/copy`, copiedData, { headers })
      .then(() => console.log("Copy request sent"))
      .catch(error => console.error("Error sending copy request:", error));

    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000); // Hide notification after 2 seconds
  };

  const handleTranslate = async (inputText = null) => {
    setIsLoading(true);
    let textToTranslate;

    if (inputText === null) {
      textToTranslate = text;
    } else if (typeof inputText === 'object') {
      // If inputText is an object, it's likely coming from the button click event
      // In this case, we should use the text from the state
      textToTranslate = text;
    } else {
      textToTranslate = inputText;
    }

    console.log("Text to translate: ", textToTranslate);
    if (!textToTranslate) {
      console.log("No text to translate");
      setIsLoading(false);
      return;
    }
    let totalCharsAvailable;
    let charLimit;
  
    try {
      // Count the characters in the text
      const chars = countCharacters(textToTranslate);
  
      // Get the access token
      const token = await getAccessToken();
  
      let paidCharsAvailable = 0;
      let freeCharsAvailable = 0;
  
      // Check if user is registered/logged in and the amount of available characters
      if (!token) {
        console.log("User is not registered");
        charLimit = charLimitFree; // Set free character limit
        freeCharsAvailable = getCharactersLeftThisMonth(charLimit);
        totalCharsAvailable = freeCharsAvailable;
        console.log('Characters of current text:', chars);
        console.log('Free characters left this month:', freeCharsAvailable);
      } else {
        charLimit = charLimitRegistered; // Set registered user character limit
        freeCharsAvailable = getCharactersLeftThisMonth(charLimit);
        // Check if user has bought characters
        console.log("Making quota request: ");
        const quotaResponse = await axios.post(`${BASE_URL}/token/quota`, { hello: "world" }, { headers: { Authorization: `Bearer ${token}` } });
        console.log("Quota response: ", quotaResponse.data);
        paidCharsAvailable = quotaResponse.data[0].characters_available;
        totalCharsAvailable = paidCharsAvailable + freeCharsAvailable;
        console.log("Total chars available: ", totalCharsAvailable, " = ", paidCharsAvailable, " (paid) + ", freeCharsAvailable, " (free)");
      }
  
      // Check if user is eligible for request
      if (totalCharsAvailable < chars) {
        console.log("Not enough chars left for request: " + totalCharsAvailable);
        setShowAlert(true);
        return;
      } else {
        console.log("Enough chars left for request: " + totalCharsAvailable);
      }
  
      const headers = { Authorization: `Bearer ${token}` };

      // Prepare the data to be translated
      const translateData = {
        text: textToTranslate,
        language_level: state.languageLevel,
        summarize: parseInt(state.summarize),
      };
  
      // Call the translate endpoint
      let translateResponse;
      // try {
      try {
        console.log("First Translate Request: ", translateData);
        console.log("Header: ", headers);
        translateResponse = await axios.post(`${BASE_URL}/translate`, translateData, { headers });
      } catch (error) {
        throw error;
      };
  
      if (translateResponse.status === 200) {
        if (token) {
          // Deduct characters from paid quota first, then from free quota if necessary
          let charsToDeductFromPaid = Math.min(chars, paidCharsAvailable);
          let charsToDeductFromFree = chars - charsToDeductFromPaid;
  
          console.log(`Deducting ${charsToDeductFromPaid} from paid quota and ${charsToDeductFromFree} from free quota.`);
  
          if (charsToDeductFromPaid > 0) {
            console.log("Deducting from paid quota: ");
            const deductQuotaResponse = await axios.post(
              `${BASE_URL}/token/change-quota`,
              { n_characters: -charsToDeductFromPaid },
              { headers: { Authorization: `Bearer ${token}` } }
            );
            console.log("Deducted from paid quota: ", deductQuotaResponse.data);
          }
  
          if (charsToDeductFromFree > 0) {
            // Deduct from free quota stored in cookies
            const updatedCharsLeft = freeCharsAvailable - charsToDeductFromFree;
            updateCharacterCount(charsToDeductFromFree); // Function to update cookies
            console.log("Deducted from free quota. Characters left this month:", updatedCharsLeft);
          }
        } else {
          // Deduct from the free quota stored in cookies for non-registered users
          const updatedCharsLeft = freeCharsAvailable - chars;
          updateCharacterCount(chars); // Function to update cookies
          console.log("Deducted from free quota. Characters left this month:", updatedCharsLeft);
        }
        setTranslationsMarkdown(translateResponse.data[0]["formatted_text"].replace("\n", "")); // Set the translated text
        setTranslations(translateResponse.data[0]["formatted_text"].replace("\n", "")); // Set the translated text
        // setTranslations(translateResponse.data[0]["simplified_text"]); // Set the translated text
        
        if (state.showMarkdown) {
          setTranslationsShown(translateResponse.data[0]["formatted_text"]);
        } else {
          setTranslationsShown(translateResponse.data[0]["simplified_text"]);
        }

        setComplexity(100 - translateResponse.data[0]["complexity"]["before"]["average_readability_score"]);
        setGERLevel(translateResponse.data[0]["complexity"]["before"]["ger_language_level"]);
        setGrade(translateResponse.data[0]["complexity"]["before"]["estimated_grade_level"]);
        
        setSimplifiedComplexity(100 - translateResponse.data[0]["complexity"]["after"]["average_readability_score"]);
        setSimplifiedGERLevel(translateResponse.data[0]["complexity"]["after"]["ger_language_level"]);
        setSimplifiedGrade(translateResponse.data[0]["complexity"]["after"]["estimated_grade_level"]);
        

        } else {
          console.error("Translation Error: ", translateResponse.data);
        }
  
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const exampleTexts = [
    "Willkommen bei unserem Leichte-Sprache-Übersetzer!\nUnser Ziel ist es, Leichte Sprache für alle zugänglich zu machen.\n\nMithilfe moderner Sprachmodelle, die ständig weiterentwickelt werden, übersetzen wir Texte in leicht verständliche Sprache. Probieren Sie es aus!",
    "Leichte Sprache ist eine Form der deutschen Sprache, die besonders einfach ist. Sie hilft Menschen, die Schwierigkeiten beim Verstehen haben. Leichte Sprache folgt festen Regeln und verwendet einfache Wörter und kurze Sätze.",
    "Wir speichern Ihre Daten nur für den Zeitraum, der für die Erbringung unserer Dienstleistung notwendig ist. Nach Ablauf dieser Frist werden Ihre Daten sicher gelöscht. Ihre Daten werden nicht an Dritte weitergegeben."
  ];

  const handleExampleClick = (example) => {
    console.log("Example clicked: ", example);
    setText(example);
    setCharCount(example.length);
    handleTranslate(example);
  };

  return (
    <div className="container mx-auto px-4">
      <div className="text-center mb-8">
        <div className='info-page'>
          <h1 className="text-3xl font-bold m-8">Leichte Sprache Übersetzer Online</h1>
          <p className="text-xl mb-8">
            Machen wir die Welt ein bisschen leichter.
          </p>
          <p className="text-base">Mit unserem Leichte Sprache Übersetzer können Sie schnell und kostenlos online Texte umschreiben - sowohl in Leichter als auch <a href="/blog/leicht-einfach" target="_blank">Einfacher Sprache</a>.</p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-6">
        {/* Left Panel */}
        <div className="w-full lg:w-1/2">
          <div className="bg-white p-6 h-full shadow-md rounded-md" style={{border: '1px solid #d1d5db'}}>
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4">
              <Form.Select 
                aria-label="Select language" 
                size="sm" 
                value={state.languageLevel} 
                onChange={(e) => handleLanguageChange(e.target.value)} 
                className="mb-2 sm:mb-0 sm:mr-2 rounded-md border-gray-300 w-full sm:w-1/4"
              >
                <option value="leichte-sprache">Leichte Sprache</option>
                {/* <option value="einfache-sprache">Einfache Sprache</option> */}
                <option value="einfache-sprache-2">Einfache Sprache</option>
              </Form.Select>
              <Form.Text className="text-sm">
                {getLanguageText(state.languageLevel)}
              </Form.Text>
            </div>
            <div className="relative">
              <textarea
                id="textAreaExample"
                rows={10}
                ref={textAreaRef}
                value={text}
                onChange={handleTextChange}
                placeholder='Geben Sie hier Ihren Text ein.'
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.ctrlKey) {
                    handleTranslate();
                  }
                }}
                className="w-full p-3 border rounded-md resize-none text-base focus:outline-none focus:ring-2 focus:ring-primary-light-2 hover:shadow-md"
              />
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mt-2 mb-4">
                <div className="mb-2 sm:mb-0">
                  <span className="text-sm text-gray-500">
                    {charCount} / 1800 Zeichen
                  </span>
                </div>
                <div className="flex gap-2">
                  {exampleTexts.map((example, index) => (
                    <button
                      key={index}
                      onClick={() => handleExampleClick(example)}
                      className="bg-white hover:bg-primary-light-3 text-sm py-1 px-2 rounded-xl border border-gray-400 text-gray-600"
                    >
                      Beispiel {index + 1}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between items-center mt-4">
              <RadioButtonGroup defaultValue={state.summarize} onChange={handleSummarize} />
              <Button 
                onClick={handleTranslate} 
                disabled={isLoading} 
                className="mt-4 ml-8 md:mt-0 bg-primary hover:bg-primary-dark-1 text-white font-bold py-2 px-4 rounded-md whitespace-nowrap overflow-hidden text-ellipsis"
                title="Einfacher machen"
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : (
                  'Einfacher machen'
                )}
              </Button>
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div className="w-full lg:w-1/2">
          <div className="bg-white p-6 h-full shadow-md rounded-md" style={{border: '1px solid #d1d5db'}}>
            <div className="flex items-center mb-5">
              <label className="mr-2 text-base">
                Binde-Strich
              </label>
              <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                <input 
                  type="checkbox" 
                  id="medio-switch" 
                  checked={!state.mediopoint}
                  onChange={handleToggle}
                  className="sr-only"
                />
                <label 
                  htmlFor="medio-switch" 
                  className="block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                >
                  <span 
                    className={`block h-6 w-6 rounded-full bg-white shadow transform transition-transform duration-200 ease-in-out border border-gray-400 ${
                      state.mediopoint ? 'translate-x-4' : 'translate-x-0'
                    }`}
                  ></span>
                </label>
              </div>
              <label className="ml-2 text-base">
                Medio·Punkt
              </label>
            </div>

            <div className="relative">
            <div 
              className="custom-pre p-3 text-base text-black border rounded-md min-h-[262px] max-h-[262px] overflow-y-auto [&>h1]:text-xl [&>h1]:font-bold [&>h1]:mb-4 [&>h2]:text-lg [&>h2]:font-semibold [&>h2]:mb-3" 
              dangerouslySetInnerHTML={{ __html: translationsShown }} 
            />
              <Button
                onClick={handleCopy}
                className="absolute bottom-4 right-4 bg-transparent hover:bg-gray-100 text-gray-800 p-2 rounded border-0"
              >
                <FontAwesomeIcon icon={copySuccess ? faCheck : faCopy} />
              </Button>
              {copySuccess && (
                <div className="absolute bottom-0 right-4 bg-black text-white px-2 py-1 rounded text-sm">
                  Kopiert!
                </div>
              )}
            </div>
            <p className="text-sm mt-4 pl-2">
              Zusätzliche Begriffs-Erklärungen finden Sie in unserem <a href="/glossar" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Wörter-Buch.</a>
            </p>
          </div>
        </div>
      </div>

      <div className="text-center mt-6">
        <span className="text-xs max-w-4xl mx-auto block px-4">
          Durch das Übersetzen von Texten mit dem Leichte Sprache Übersetzer stimmst du unseren Nutzungsbedingungen zu und bestätigst, dass du unsere <a href="/datenschutz" className="text-blue-500 hover:underline">Datenschutzerklärung</a> gelesen hast.
        </span>
      </div>

      <div className="flex flex-col lg:flex-row gap-6 mt-6">
        <div className="w-full lg:w-1/2">
          <ComplexityCard title="Vorher" complexity={complexity} level={GERLevel} grade={grade} alignment="left" />
        </div>
        <div className="w-full lg:w-1/2">
          <ComplexityCard title="Nachher" complexity={simplifiedComplexity} level={simplifiedGERLevel} grade={simplifiedGrade} alignment="right" />
        </div>
      </div>

      {showAlert && (
        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible className="mt-6">
          <Alert.Heading>Zeichen·Grenze erreicht</Alert.Heading>
          <p>Der Text überschreitet Ihre monatliche Zeichen·Grenze.</p>
          {!isLoggedIn && <p>Bitte loggen Sie sich ein. Oder registrieren Sie sich. Dann bekommen Sie mehr Zeichen.</p>}
        </Alert>
      )}
      {showLimitAlert && (
        <Alert variant="warning" onClose={() => setShowLimitAlert(false)} dismissible className="mt-6">
          <Alert.Heading>Zeichen·Grenze erreicht</Alert.Heading>
          <p>Der Text war zu lang. Er wurde auf {charsPerPage} Zeichen gekürzt.</p>
        </Alert>
      )}

      
    </div>
  );  
};

export default LeichteSpracheTranslator;


function toggleHyphenMediopoint(text, toMediopoint) {
  if (text === '' || text === null || text === undefined) {
    return text;
  }
  if (toMediopoint) {
    // Replace hyphens with mediopoints between letters
    return text.replace(/([a-zA-Z])-([a-zA-Z])/g, '$1·$2');
  } else {
    // Replace mediopoints with hyphens between letters
    return text.replace(/([a-zA-Z])·([a-zA-Z])/g, '$1-$2');
  }
}

function updateCharacterCount(chars) {
  const currentMonth = new Date().toISOString().slice(0, 7); // Format YYYY-MM
  let charUsage = JSON.parse(getCookie('charUsage') || '{}');

  // Clean old cookies
  charUsage = cleanOldCookies(charUsage);

  if (!charUsage[currentMonth]) {
    charUsage[currentMonth] = 0;
  }

  charUsage[currentMonth] += chars;

  setCookie('charUsage', JSON.stringify(charUsage), 30); // Store for 30 days
  console.log('Updated charUsage:', charUsage); // Debugging
}

function stripHtmlTags(html) {
  // Step 1: Remove all HTML tags except for <br>, <p>, <div>, <ul>, <ol>, <li>, <h1>, <h2>, <h3>, etc.
  let tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  
  // Step 2: Replace <br>, <p>, <div> with line breaks
  let text = tempDiv.innerHTML.replace(/<br\s*\/?>/gi, '\n')
                              .replace(/<\/p>|<\/div>/gi, '\n')
                              .replace(/<p>|<div>/gi, '');
  
  // Step 3: Add line breaks before and after headings
  text = text.replace(/<\/h[1-6]>/gi, '\n\n')
             .replace(/<h[1-6]>/gi, '\n');

  // Step 4: Convert <ul> and <ol> to line breaks and <li> to dashes
  text = text.replace(/<\/ul>|<\/ol>/gi, '\n')  // Replace </ul> and </ol> with line breaks
             .replace(/<ul>|<ol>/gi, '')  // Remove <ul> and <ol>
             .replace(/<li>/gi, '- ')  // Replace <li> with dashes
             .replace(/<\/li>/gi, '\n');  // Replace <li> with line breaks

  // Step 5: Remove remaining HTML tags
  text = text.replace(/<\/?[^>]+(>|$)/g, "");

  // Step 6: Trim leading and trailing whitespace
  text = text.trim();

  return text;
}
