import React from 'react';
import { Helmet } from 'react-helmet';

const Mission = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mission | Leichte Sprache Übersetzer</title>
        <meta name="description" content="Mission - Leichte Sprache Übersetzer" />
        <meta name="keywords" content="Mission, Leichte Sprache Übersetzer, Einfache Sprache" />
        <meta property="og:title" content="Mission - Leichte Sprache Übersetzer" />
        <meta property="og:description" content="Mission - Leichte Sprache Übersetzer" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/glossar_component_screenshot.webp" />
        <meta property="og:image:alt" content="Illustration related to Leichte Sprache" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Leichte Sprache Übersetzer" />
      </Helmet>
      <article className="prose lg:prose-xl">
        <h1 className="text-4xl font-bold mb-6 text-center">Unsere Mission</h1>
        <h2 className="text-xl font-semibold mb-8 text-center">Leichte Sprache für alle zugänglich machen</h2>
        
        <div className="space-y-6">
          <p>Unsere Mission ist es, Leichte Sprache für alle zugänglich zu machen.</p>
          <div className="flex justify-center items-center">
            <img src="/imgs/other/helping-hand-2.webp" alt="A helping hand" className="max-w-full h-auto" width="864" height="584"/>
          </div>
          <p>Künstliche Intelligenz ermöglicht es uns schon lange, Texte automatisiert zwischen Sprachen zu übersetzen, wie Google Translate und DeepL eindrücklich zeigen.</p>
          <h2>Automatisierte Übersetzungen in Leichte Sprache</h2>
          <p>Leichte Sprache hingegen war bis vor Kurzem noch kein Gegenstand automatisierter Übersetzungen. Firmen wie Google und DeepL haben sich bislang nicht dazu entschieden, ihr Angebot auf Leichte und Einfache Sprache zu erweitern.</p>
          <p>Und das hat Konsequenzen: Nur ein Bruchteil des Internets ist derzeit in Leichter Sprache verfügbar. Selbst wenn ein Leichte-Sprache-Button auf einer Webseite vorhanden ist, führt einen dieser üblicherweise zu einer Unterseite, die mit der aktuellen Seite gar nichts zu tun hat.</p>
          <p>Das kommt daher, dass viele Betreiber von Webseiten nicht die Ressourcen haben, um alle Webinhalte in Leichter Sprache anzubieten.</p>
          <p>Damit wird Menschen mit Lernschwierigkeiten oder einer Lese-Rechtschreibschwäche der Zugang zum Internet ein Stück weit verwehrt.</p>
          <h2>Unser Auftrag</h2>
          <p>Unsere Mission: Die Übersetzung in Leichte Sprache so einfach zu gestalten, dass Betreiber von Webinhalten nicht mehr lange überlegen müssen.</p>
          <p>Weder können noch wollen wir mit unserem Angebot die wichtige Arbeit von Übersetzern und Übersetzerinnen für Leichte Sprache ersetzen. Doch glauben wir, dass der niedrige Anteil von Webinhalten in Leichter Sprache Bände spricht – und niedrigschwellige Angebote erfordert.</p>
          <p>Wir wollen ein niedrigschwelliges Angebot für Übersetzungen in Leichte Sprache bereitstellen und damit das Internet ein bisschen leichter machen.</p>
        </div>
      </article>
    </div>
  );
};

export default Mission;
