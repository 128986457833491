import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"

import { AuthProvider } from './context/AuthContext';
import AuthWrapper from './components/auth/AuthWrapper';
import ScrollToTop from './utils/scroll';

import NavbarComponent from './components/NavbarComponent';
import FooterComponent from './components/FooterComponent';
import Home from './pages/Home';
import BlogPost from './components/blog/BlogPost';
import UberUns from './pages/UberUns';
import Mission from './pages/Mission';
import Glossary from './pages/Glossary';
import BlogList from './components/blog/BlogList';
import Pricing from './pages/Pricing';

// const Home = lazy(() => import('./pages/Home'));
// const Glossary = lazy(() => import('./pages/Glossary'));
// const GlossaryTerm = lazy(() => import('./pages/GlossaryTerm'));
const GlossaryTerm = lazy(() => import('./pages/GlossaryTermNew'));
// const UberUns = lazy(() => import('./pages/UberUns'));
// const Mission = lazy(() => import('./pages/Mission'));
const Datenschutz = lazy(() => import('./pages/Datenschutz'));
const Impressum = lazy(() => import('./pages/Impressum'));
const Login = lazy(() => import('./pages/Login'));
const Profile = lazy(() => import('./pages/Profile'));
const Contact = lazy(() => import('./pages/Contact'));
const ActivateAccount = lazy(() => import('./pages/ActivateAccount'));
// const BlogList = lazy(() => import('./components/blog/BlogList'));
// const BlogPost = lazy(() => import('./components/blog/BlogPost'));

// Pricing related pages
// const Pricing = lazy(() => import('./pages/Pricing-placeholder'));
// const Pricing = lazy(() => import('./pages/Pricing'));
const Checkout = lazy(() => import('./pages/Checkout'));
const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'));

function App() {
  

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      localStorage.setItem('access_token', "");
    }

    if (!localStorage.getItem('refresh_token')) {
      localStorage.setItem('refresh_token', "");
    }
  }, []);
  

  return (
    <AuthProvider>
      <AuthWrapper>
        <Router>
          <SpeedInsights/>
          <Analytics/>
          <NavbarComponent />
          {/* <CookieBanner /> */}
          <Container className="mt-4">
            <ScrollToTop />
            <Suspense fallback={<div></div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/glossar" element={<Glossary />} />
                <Route path="/glossar/:term" element={<GlossaryTerm />} />
                

                <Route path="/uber-uns" element={<UberUns />} />
                <Route path="/mission" element={<Mission />} />
                <Route path="/datenschutz" element={<Datenschutz />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/activate-account" element={<ActivateAccount />} />
                <Route path="/blog" element={<BlogList />} />
                <Route path="/blog/:slug" element={<BlogPost />} />
                <Route path="/pricing" element={<Pricing />} /> 
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/success" element={<PaymentSuccess />} />
                
                
              </Routes>
            </Suspense>
          </Container>
          <FooterComponent />
        </Router>
      </AuthWrapper>
    </AuthProvider>
  );
}

export default App;
