// src/data/postsData.js
export const posts = [
  { 
    title: 'Was kostet eine Übersetzung in Leichte Sprache?', 
    slug: 'kosten', 
    description: 'Es gibt mittlerweile viele Möglichkeiten, Texte in Leichte Sprache zu übersetzen: Ob man dafür eine*n ausgebildete*n Übersetzer*in beauftragt, ChatGPT oder unseren Leichte Sprache Übersetzer.', 
    img: "../imgs/posts/kosten/kosten-small.webp",
    keywords: "Leichte Sprache, Kosten, Preis, Übersetzung, ChatGPT",
    date: "02.10.2024",
    author: "Lukas Hecker"
  },
  { 
    title: 'Texte mit ChatGPT in Leichte Sprache Übersetzen', 
    slug: 'chatgpt', 
    description: 'Viele Menschen nutzen mittlerweile ChatGPT, um Texte in Leichte Sprache zu übersetzen. Wir haben mit gängigen Verfahren aus der Linguistik geprüft, wie gut das gelingt.', 
    img: "../imgs/posts/vs_chatgpt/hero-small.webp",
    keywords: "ChatGPT, Leichte Sprache, ChatGPT Leichte Sprache, ChatGPT Übersetzer",
    date: "23.09.2024",
    author: "Lukas Hecker"

  },
  { 
    title: 'Die Regeln Leichter Sprache', 
    slug: 'regeln', 
    description: 'Was sind die Regeln Leichter Sprache? Eine einfache Frage mit einer nicht ganz so einfachen Antwort. Heute gehen wir den Regeln Leichter Sprache...', 
    img: "../imgs/posts/checklist_thumbnail.webp",
    keywords: "Regeln Leichte Sprache, Leichte Sprache Regeln, Leichte Sprache, Regeln",
    date: "28.08.2024",
    author: "Lukas Hecker"
  },
  { 
    title: 'Der Unterschied zwischen Leichter und Einfacher Sprache', 
    slug: 'leicht-einfach', 
    description: 'Viele Menschen nutzen die Begriffe Leichte Sprache und Einfache Sprache, ohne den Unterschied zu kennen. Die beiden Sprachformen haben...', 
    img: "../imgs/posts/bauklotz_thumbnail.webp",
    keywords: "Leichte Sprache, Einfache Sprache, Leichte vs Einfache Sprache, Unterschied",
    date: "19.08.2024",
    author: "Lukas Hecker"
  },
  { 
    title: 'Texte Kostenlos in Leichte Sprache Übersetzen', 
    slug: 'kostenlos', 
    description: 'Wer Texte in Leichte Sprache übersetzen will hat heute viele Optionen. Soll das ganze allerdings kostenlos sein wird es schon schwieriger. Wir zeigen...',
    // description: 'Wer Texte in Leichte Sprache übersetzen will hat heute viele Optionen. Wir zeigen dir alle Wege um Texte kostenlos in Leichte Sprache zu übersetzen.',
    img: "../imgs/posts/sprechblase_kreativ_thumbnail.webp",
    keywords: "Leichte Sprache Übersetzer kostenlos, Leichte Sprache ChatGPT, Leichte Sprache Übersetzer, Leichte Sprache KI",
    date: "16.08.2024",
    author: "Lukas Hecker"
  },
  { 
    title: 'Beispiele für Leichte Sprache', 
    slug: 'beispiele', 
    description: 'Immer mehr Webseiten wollen Inhalte in Leichter Sprache anbieten. Eine häufige Frage dabei ist: Wie muss Leichte Sprache eigentlich...' , 
    // description: 'Wie sieht Leichte Sprache eigentlich aus? Welche Regeln für Leichte Sprache müssen beachtet werden? Heute schauen wir uns Beispiele für Leichte Sprache an.',
    img: "../imgs/posts/wheelchair_thumbnail.webp",
    keywords: "Leichte Sprache, Beispiele, Webseiten, Barrierefreiheit",
    date: "11.08.2024",
    author: "Lukas Hecker"
  },
  
];
