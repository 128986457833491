import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import LeichteSpracheTranslator from '../components/LeichteSpracheTranslator/LeichteSpracheTranslator';

const FAQ = lazy(() => import('../components/LeichteSpracheTranslator/FAQ'));

const Translator = () => {
  return (
    <div className="App">
      <Helmet>
        {/* <!-- Essential Meta Tags --> */}
        {/* encoding */}
        {/* <meta charSet="UTF-8" /> */}
        <title>Leichte Sprache Übersetzer | Kostenlos Texte Online Umschreiben</title>
        <meta name="title" content="Leichte Sprache Übersetzer | Kostenlos Texte Online Umschreiben" />
        {/* <meta name="description" content="Nutzen Sie den Leichte Sprache Online Übersetzer, um Texte schnell und kostenlos zu vereinfachen. Verbessern Sie die digitale Barrierefreiheit für Ihre Inhalte." />
         */}
        <meta name="description" content="Nutze unseren kostenlosen Leichte Sprache Übersetzer. Texte online umschreiben. Ideal für digitale Barrierefreiheit und leicht verständliche Kommunikation." />
        <meta name="keywords" content="Leichte Sprache Übersetzer, Leichte Sprache Online, Texte vereinfachen, Text umschreiben, digitale Barrierefreiheit, einfache Sprache, Textübersetzung, barrierefreie Kommunikation" />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Leichte Sprache Übersetzer | Kostenlos Texte Online Umschreiben" />
        <meta property="og:description" content="Nutze unseren kostenlosen Leichte Sprache Übersetzer. Texte online umschreiben. Ideal für digitale Barrierefreiheit und leicht verständliche Kommunikation." />
        <meta property="og:url" content="https://www.leichte-sprache-uebersetzer.de" />
        <meta property="og:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/component_screenshot.webp" />
        <meta property="og:site_name" content="Leichte Sprache Übersetzer" />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:image:alt" content="Leichte Sprache Online Übersetzer Screenshot" />

        {/* <!-- Twitter --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Leichte Sprache Übersetzer | Kostenlos Texte Online Umschreiben" />
        <meta name="twitter:description" content="Nutze unseren kostenlosen Leichte Sprache Übersetzer. Texte online umschreiben. Ideal für digitale Barrierefreiheit und leicht verständliche Kommunikation." />
        <meta name="twitter:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/component_screenshot.webp" />
        <meta name="twitter:image:alt" content="Leichte Sprache Online Übersetzer Screenshot" />

        {/* <!-- Canonical URL --> */}
        <link rel="canonical" href="https://www.leichte-sprache-uebersetzer.de/" />

        {/* <!-- Additional SEO and Accessibility Tags --> */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Lukas Hecker" />
        <meta httpEquiv="Content-Language" content="de" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        
        {/* <!-- Schema.org Markup for HomePage --> */}
        <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://www.leichte-sprache-uebersetzer.de/",
            "name": "Leichte Sprache Übersetzer",
            "description": "Nutze unseren kostenlosen Leichte Sprache Übersetzer. Texte online umschreiben. Ideal für digitale Barrierefreiheit und leicht verständliche Kommunikation.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://www.leichte-sprache-uebersetzer.de/glossar/{search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
        `}
        </script>
      </Helmet>
      
      
      {/* Set a fixed height for the LeichteSpracheTranslator component */}
      <div>
        <LeichteSpracheTranslator />
      </div>

      <div className="h-px bg-gradient-to-r from-transparent via-gray-400 to-transparent shadow-md my-16"></div>
      
      <div className="mt-12 max-w-3xl mx-auto">
        <h1 className='text-center text-3xl font-bold mb-6'>FAQ</h1>
        <Suspense fallback={<div style={{ height: '500px' }}>Loading FAQ...</div>}>
          <FAQ />
        </Suspense>
      </div>
    </div>
  );
};

export default Translator;
