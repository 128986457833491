import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './FooterComponent.css';  // Create this CSS file to style the footer

const FooterComponent = () => {
  return (
    <footer className="footer mt-auto py-3 bg-light">
      <Container>
        <Row>
          <Col md={4}>
            <h4>Über uns</h4>
            <ul className="list-unstyled text-base mt-3">
              {/* <li><Link to="/uber-uns">Wer wir sind</Link></li> */}
              <li><Link to="/mission">Mission</Link></li>
              <li><Link to="/datenschutz">Datenschutz</Link></li>
              <li><Link to="/impressum">Impressum</Link></li>
            </ul>
          </Col>
          <Col md={4}>
            <h4>Support</h4>
            <ul className="list-unstyled text-base mt-3">
              <li><Link to="/pricing">Preise</Link></li>
              <li><Link to="/contact">Kontakt</Link></li>
              {/* <li><Link to="/faq">FAQ</Link></li> */}
            </ul>
          </Col>
          <Col md={4}>
            <h4>Folgen Sie uns</h4>
            <ul className="list-unstyled social-links text-base mt-3">
              <li><a href="https://www.linkedin.com/company/leichte-sprache-%C3%BCbersetzer/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center text-sm mt-3">
            <p>&copy; 2024 Leichte Sprache Übersetzer. Alle Rechte vorbehalten.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterComponent;
