import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-bootstrap';
import '../../App.css';

const RadioButtonGroup = ({ defaultValue, onChange }) => {
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const renderTooltip = (props, text) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <div className="text-center z-10">
      <p className="text-base mb-2">Kürzung</p>
      <div className="flex items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="relative cursor-pointer" htmlFor="stark">
            <input
              type="radio"
              name="summarization"
              id="stark"
              className="peer cursor-pointer"
              value="2"
              checked={selectedValue === '2'}
              onChange={handleChange}
            />
            <label
              htmlFor="stark"
              className="flex flex-col items-center cursor-pointer"
              title="Kürzt den Text stark. Umfasst nur die wichtigsten Informationen."
            >
              <span className="mt-0 text-sm">Stark</span>
            </label>
          </div>
        </div>

        <div className="w-12 h-0.5 bg-primary-color mx-2 mt-2.5"></div>

        <div className="flex flex-col items-center">
          <div className="relative cursor-pointer" htmlFor="mittel">
            <input
              type="radio"
              name="summarization"
              id="mittel"
              className="peer cursor-pointer"
              value="1"
              checked={selectedValue === '1'}
              onChange={handleChange}
            />
            <label
              htmlFor="mittel"
              className="flex flex-col items-center cursor-pointer"
              title="Kürzt den Text leicht. Umfasst die meisten Informationen."
            >
              <span className="mt-0 text-sm">Leicht</span>
            </label>
          </div>
        </div>

        <div className="w-12 h-0.5 bg-primary-color mx-2 mt-2.5"></div>

        <div className="flex flex-col items-center">
          <div className="relative cursor-pointer" htmlFor="nicht">
            <input
              type="radio"
              name="summarization"
              id="nicht"
              className="peer cursor-pointer"
              value="0"
              checked={selectedValue === '0'}
              onChange={handleChange}
            />
            <label
              htmlFor="nicht"
              className="flex flex-col items-center cursor-pointer"
              title="Der vereinfachter Text hat in etwa die Länge des Originaltextes."
            >
              <span className="mt-0 text-sm">Keine</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadioButtonGroup;
