import React from 'react';
import CircularProgress from './CircularProgress';

const ComplexityCard = ({ title, complexity, level, grade, alignment = 'left' }) => {
  const alignmentClass = alignment === 'right' ? 'ml-auto' : '';

  return (
    <div className={`w-1/2 bg-white p-6 shadow-md rounded-md h-full ${alignmentClass}`} style={{border: '1px solid #d1d5db'}}>
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-sm text-gray-600 mb-4">Schwierigkeit</p>
      <div className="flex flex-col mb-4">
        <CircularProgress complexity={complexity} />
        <div className="mt-4">  
          <p className="text-sm mb-1">Sprach-Niveau: <span className="font-semibold">{level}</span></p>
          <p className="text-sm">Schul-Klasse: <span className="font-semibold">{grade}</span></p>
        </div>
      </div>
    </div>
  );
};

export default ComplexityCard;
