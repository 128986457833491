import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/logo_inverted_150px.png';

const NavbarComponent = () => {
  const { authState, setUser } = useAuth();
  const { user } = authState;

  const [expanded, setExpanded] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const handleLogout = () => {
    setUser(null);
    localStorage.setItem('access_token', '');
    localStorage.setItem('refresh_token', '');
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleNavItemClick = () => {
    if (window.innerWidth <= 991) {
      setExpanded(false);
    }
  };

  return (
    <nav className="h-16 bg-white border-b  sticky z-50 top-0 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="flex items-center no-underline" onClick={handleNavItemClick}>
                <img
                  src={logo}
                  width="50"
                  height="50"
                  className="mr-2"
                  alt="Logo"
                />
                <span className="text-black text-md">Übersetzer</span>
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8  mb-1">
              <NavLink to="/uber-uns">Über uns</NavLink>
              <NavLink to="/mission">Mission</NavLink>
              <NavLink to="/glossar">Wörterbuch</NavLink>
              <NavLink to="/blog">Blog</NavLink>
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {user ? (
              <div className="ml-3 relative">
                <div>
                  <button
                    type="button"
                    className="inline-flex items-center justify-between w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white no-underline bg-primary hover:bg-primary-dark-1"
                    id="user-menu"
                    aria-expanded={dropdownOpen}
                    aria-haspopup="true"
                    onClick={toggleDropdown}
                  >
                    <span className="flex items-center">
                      {user.email}
                    </span>
                    <svg className="ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                  </button>
                </div>
                {dropdownOpen && (
                  <div className="origin-top-right absolute border-1 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                    <Link to="/profile" className="block text-black px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 no-underline" role="menuitem" onClick={() => setDropdownOpen(false)}>Konto</Link>
                    <button onClick={() => { handleLogout(); setDropdownOpen(false); }} className="block bg-white border-0 w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-500" role="menuitem">
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <Link
                to="/login"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white no-underline bg-primary hover:bg-primary-dark-1 "
                onClick={handleNavItemClick}
              >
                Anmelden
              </Link>
            )}
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setExpanded(!expanded)}
            >
              <span className="sr-only">Open main menu</span>
              {/* Icon when menu is closed */}
              <svg className={`${expanded ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              {/* Icon when menu is open */}
              <svg className={`${expanded ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state. */}
      <div className={`${expanded ? 'block' : 'hidden'} sm:hidden`} id="mobile-menu">
        <div className="pt-2 pb-3 space-y-1">
          <MobileNavLink to="/uber-uns">Über uns</MobileNavLink>
          <MobileNavLink to="/mission">Mission</MobileNavLink>
          <MobileNavLink to="/glossar">Wörterbuch</MobileNavLink>
          <MobileNavLink to="/blog">Blog</MobileNavLink>
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          {user ? (
            <div className="flex items-center px-4">
              <div className="flex-shrink-0">
                <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                  <span className="text-sm font-medium leading-none text-white">{user.email.charAt(0).toUpperCase()}</span>
                </span>
              </div>
              <div className="ml-3">
                <div className="text-base font-medium text-gray-800">{user.email}</div>
              </div>
            </div>
          ) : (
            <div className="mt-3 space-y-1">
              <Link
                to="/login"
                className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                onClick={handleNavItemClick}
              >
                Anmelden
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

const NavLink = ({ to, children }) => (
  <Link
    to={to}
    className="text-black text-base inline-flex items-center px-1 pt-1 border-b-0 border-transparent leading-5 transition duration-150 ease-in-out no-underline hover:shadow-md"
  >
    {children}
  </Link>
);

const MobileNavLink = ({ to, children }) => (
  <Link
    to={to}
    className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 transition duration-150 ease-in-out"
  >
    {children}
  </Link>
);

export default NavbarComponent;