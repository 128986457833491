import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../config';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isLoggedIn: false,
    loading: true,
    user: null,
    tokenChecked: false, // Add this new state
  });

  const refreshToken = useCallback(async () => {
    const refreshToken = localStorage.getItem('refresh_token');
    const accessToken = localStorage.getItem('access_token');

    if (!refreshToken || !accessToken) {
      console.error('No tokens found in local storage');
      return { success: false };
    }

    const tokenData = { refresh_token: refreshToken, access_token: accessToken };

    try {
      const response = await axios.post(`${BASE_URL}/token/refresh-token`, tokenData);
      const { access_token, refresh_token } = response.data;

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);

      return { success: true };
    } catch (error) {
      console.error('Failed to refresh token', error);
      return { success: false };
    }
  }, []); // Add dependencies here if refreshToken depends on any state or props

  const checkToken = useCallback(async () => {
    if (authState.tokenChecked) return authState.isLoggedIn; // Add this line

    const token = localStorage.getItem('access_token');
    if (!token) {
      setAuthState(prev => ({ ...prev, isLoggedIn: false, loading: false, tokenChecked: true }));
      return false;
    }
  
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.post(`${BASE_URL}/token/verify-token`, null, { headers });
  
      if (response.data.isValid) {
        setAuthState(prev => ({ ...prev, isLoggedIn: true, loading: false, tokenChecked: true }));
        return true;
      } else {
        const refreshed = await refreshToken();
        setAuthState(prev => ({ ...prev, isLoggedIn: refreshed.success, loading: false, tokenChecked: true }));
        return refreshed.success;
      }
    } catch (error) {
      console.error('Token verification failed:', error);
      setAuthState(prev => ({ ...prev, isLoggedIn: false, loading: false, tokenChecked: true }));
      return false;
    }
  }, [authState.tokenChecked, refreshToken, authState.isLoggedIn]);

  useEffect(() => {
    const initAuth = async () => {
      await checkToken();
      const savedUser = Cookies.get('user');
      setAuthState(prev => ({
        ...prev,
        user: savedUser ? JSON.parse(savedUser) : null,
      }));
    };
    initAuth();
  }, [checkToken]);

  

  

  const setUser = (user) => {
    setAuthState((prevState) => ({
      ...prevState,
      user,
      isLoggedIn: !!user,
    }));
    if (user) {
      Cookies.set('user', JSON.stringify(user), { expires: 7 });
    } else {
      Cookies.remove('user');
    }
  };

  return (
    <AuthContext.Provider value={{ authState, setUser, checkToken }}>
      {!authState.loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const { authState, setUser, checkToken } = context;

  const getAccessToken = async () => {
    const isValid = await checkToken();
    if (!isValid) {
      console.error('Token is invalid or expired');
      return '';
    }

    return localStorage.getItem('access_token');
  };

  return {
    authState,
    setUser,
    getAccessToken,
    checkToken,
  };
};
