import React, { useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';

const AuthWrapper = ({ children }) => {
  const { authState, setUser } = useAuth();
  const { isLoggedIn, user } = authState;
  const initialCheckDone = useRef(false);

  useEffect(() => {
    if (!initialCheckDone.current) {
      if (isLoggedIn) {
        console.log("User is logged in: ", user);
        setUser(user);
      } else {
        console.log("User is not logged in");
        setUser(null);
      }
      initialCheckDone.current = true;
    }
  }, [isLoggedIn, user, setUser]);

  return <>{children}</>;
};

export default AuthWrapper;