// src/pages/Pricing.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Add this import

function PricingCard({ title, subtitle, price, features, onSelect }) {
  return (
    <div className="w-full max-w-sm bg-white border border-gray-300 rounded-lg shadow-xl overflow-hidden transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1">
      <div className="px-6 py-8">
        <h3 className="text-2xl font-bold text-gray-900 mb-2">{title}</h3>
        <p className="text-gray-500 text-sm mb-4">{subtitle}</p>
        <div className="flex items-baseline mb-6">
          <span className="text-4xl font-extrabold text-gray-900">{price}</span>
          {price !== '0 €' && <span className="text-gray-500 text-sm ml-1">/ Normseite</span>}
        </div>
        <ul className="space-y-3 mb-6">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      {price !== '0 €' && (
        <div className="px-6 pb-8">
          <button
            onClick={onSelect}
            disabled
            className="w-full bg-primary text-white rounded-md py-3 font-semibold transition-colors duration-300 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Auswählen
          </button>
        </div>
      )}
    </div>
  );
}

function Pricing() {
  const navigate = useNavigate();
  const { authState, checkToken } = useAuth(); // Change this line

  const pricingTiers = [
    {
      title: '20 Normseiten',
      subtitle: '100 € inkl. MwSt.',
      price: '5 €',
      amount: 10000,
      features: ['Übersetzungen in Leichte Sprache', 'Übersetzungen in Einfache Sprache', 'Glossarfunktion'],
      id: "0001",
      numberOfCharacters: 36000
    },
    {
      title: '50 Normseiten',
      subtitle: '225 € inkl. MwSt.',
      price: '4,50 €',
      amount: 22500,
      features: ['Übersetzungen in Leichte Sprache', 'Übersetzungen in Einfache Sprache', 'Glossarfunktion', 'Angebot zur individuellen Beratung'],
      id: "0002",
      numberOfCharacters: 90000
    },
    {
      title: '100 Normseiten',
      subtitle: '400 € inkl. MwSt.',
      price: '4 €',
      amount: 40000,
      features: ['Übersetzungen in Leichte Sprache', 'Übersetzungen in Einfache Sprache', 'Glossarfunktion', 'Angebot zur individuellen Beratung'],
      id: "0003",
      numberOfCharacters: 180000
    },
  ];

  const handleSelect = (amount, id, numberOfCharacters) => {
    console.log("authState:", authState);
    const isLoggedIn = checkToken();
    if (authState.isLoggedIn && isLoggedIn) {
      // User is logged in, proceed to checkout
      console.log("User is logged in, proceeding to checkout");
      navigate(`/checkout?amount=${amount}&id=${id}&numberOfCharacters=${numberOfCharacters}`);
    } else {
      // User is not logged in, redirect to login page with return URL
      console.log("User is not logged in, redirecting to login page");
      const returnUrl = `/checkout?amount=${amount}&id=${id}&numberOfCharacters=${numberOfCharacters}`;
      navigate(`/login?returnUrl=${encodeURIComponent(returnUrl)}`);
    }
  };

  return (
    <div className="pricing-container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-center mb-12">Preise</h1>
      <p className="text-xl font-semibold mb-8 text-center">Monatlich kündbare Abonnements.</p>
      <p className="text-xl font-semibold mb-8 text-center">14 Tage Geld-zurück-Garantie.</p>
      
      <div className="pricing-tiers flex flex-wrap justify-center gap-8 my-16">
        {pricingTiers.map((tier, index) => (
          <PricingCard
            key={index}
            title={tier.title}
            subtitle={tier.subtitle}
            price={tier.price}
            features={tier.features}
            onSelect={() => handleSelect(tier.amount, tier.id, tier.numberOfCharacters)} // Route to the checkout page with the selected amount
          />
        ))}
      </div>
      <p className="text-sm mb-8 -mt-4 text-gray-500">*eine Normseite entspricht bei uns 1.800 Zeichen inklusive Leerzeichen.</p>
      <div className="flex flex-col items-center">
        <p className="text-xl mb-8">Gemeinnützige Organisationen erhalten bei uns 50 % Rabatt. Bitte kontaktieren Sie uns dafür vorab:</p>
        <a href="mailto:info@leichte-sprache-uebersetzer.de" className="text-xl font-semibold mb-8">info@leichte-sprache-uebersetzer.de</a>
      </div>
    </div>
  );
}

export default Pricing;