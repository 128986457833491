import React from 'react';
import { Helmet } from 'react-helmet';

const ProfileCard = ({ name, role, imageUrl, description }) => (
  <div className="bg-white rounded-lg shadow-md border border-gray-200 p-4 sm:p-6 flex flex-col items-center transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105">
    <img src={imageUrl} alt={`Bild von ${name}`} className="w-full max-w-[16rem] h-auto aspect-square rounded-lg mb-4 object-cover" width="256" height="256"/>
    <h2 className="text-xl sm:text-2xl font-bold mb-2">{name}</h2>
    <p className="text-base sm:text-lg text-gray-600 mb-4">{role}</p>
    <p className="text-center text-sm sm:text-base" dangerouslySetInnerHTML={{ __html: description }}></p>
  </div>
);

const UberUns = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Über uns | Leichte Sprache Übersetzer</title>
        <meta name="description" content="Über uns - Leichte Sprache Übersetzer" />
        <meta name="keywords" content="Über uns, Leichte Sprache Übersetzer, Einfach erklärt, Definition" />
        <meta property="og:title" content="Über uns - Leichte Sprache Übersetzer" />
        <meta property="og:description" content="Über uns - Leichte Sprache Übersetzer" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="https://www.leichte-sprache-uebersetzer.de/imgs/app/glossar_component_screenshot.webp" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image:alt" content="Illustration related to Leichte Sprache" />
        <meta property="og:site_name" content="Leichte Sprache Übersetzer" />
      </Helmet>
      <article className="prose lg:prose-xl">
        <div className="flex flex-col items-center justify-center mb-12">
          <h1 className="text-4xl font-bold mb-6">Über uns</h1>
          <p className="text-md mb-2">Zwei Freunde. Eine Idee.</p>
          <p className="text-md mb-2">Mit Herz und Verstand.</p>
          <p className="text-md mb-2">Verständnis schaffen</p>
          <p className="text-md mb-8">Nachteile ausgleichen.</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-12">
          <ProfileCard
            name="Lukas Hecker"
            role="Geb. 1993"
            imageUrl="imgs/other/lukas-foto.webp"
            description="B.Sc. Sensorik und kognitive Psychologie<br /><br />Mit seinem M.Sc. in Neurowissenschaften ist er Experte für Machine Learning und KI. Heute ist er als freier Berater in internationalen wissenschaftlichen Kooperationen tätig."
          />
          <ProfileCard
            name="Pascal Haller"
            role="Geb. 1992"
            imageUrl="imgs/other/pascal-foto.webp"
            description="B.Sc. Sensorik und kognitive Psychologie<br /><br />Mit seinem M.A. im Bereich Prävention und Gesundheitsmanagement arbeitet er als Führungskraft im Sozialwesen. Dort begegnet er täglich Menschen mit kognitiven und sprachlichen Einschränkungen."
          />
        </div>
        <p>Neben der langjährigen Freundschaft verbindet uns der Wunsch, soziale Nachteile auszugleichen und Positives zu bewirken.</p>
        <p>Der Plan: Hürden abbauen und ein niederschwelliges Angebot Leichter Sprache für die breite Masse schaffen.</p>
        <p>Unter dem Motto "ein Herz für Helfer" möchten wir gemeinnützigen Organisationen und Vereinen qualitativ hochwertige Übersetzungen zu einem erschwinglichen Preis liefern. Hierfür bieten wir nach Nachweis 50 % Rabatt auf unser Angebot.</p>
        <p>Kontaktieren Sie uns dafür gerne <a href="mailto:info@leichte-sprache-uebersetzer.de"> per E-Mail</a>.</p>
      </article>
    </div>
  );
};

export default UberUns;
