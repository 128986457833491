import React from 'react';
import './Glossary.css';

const SearchField = ({ term, handleSubmit, onChange, loading, textAreaRef, inputClassName }) => {
  return (
    <form className="glossary-form flex justify-center" onSubmit={handleSubmit}>
      <input
        type="text"
        className={`px-5 py-2 rounded-full border border-gray-300 focus:outline-none shadow-sm hover:shadow-md ${inputClassName}`}
        value={term}
        onChange={onChange}
        placeholder="Zu erklärender Begriff"
        ref={textAreaRef}
      />
    </form>
  );
};

export default SearchField;
